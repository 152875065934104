import { useEffect } from 'react'
import {
  Grid,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Select,
  InputGroup,
  InputRightAddon,
  Checkbox,
  VStack,
  Icon,
  Link,
  Collapse,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FiChevronDown } from 'react-icons/fi'
import { useTranslation, Trans } from 'next-i18next'

import { PRIVACY_POLICY, TERMS_OF_USE } from '@/routes'

import env from '@/lib/env'
import {
  DEFAULT_TOAST_OPTIONS,
  getErrorToastObject,
} from '@/lib/utils/format/toast'
import requests from '@/lib/requests'
import { logEvent, useUTMContext } from '@/lib/gtm'

import {
  ImplementationAdditionalFields,
  TrainingAdditionalFields,
} from './AdditionalFields'

import { FormValue, schema } from './schema'

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  tel: '',
  jobTitle: '',
  companyName: '',
  companySize: '',
  services: {
    implementation: false,
    advisory: false,
    training: false,
    legal: false,
    compliance: false,
  },
  budget: '',
  startWithIn: '',
  startWithInInput: '',
  personalInfoUsageAmount: '',
  hasImplementedPDPA: '',
  hasItSecurityTeam: '',
  transferOutCountry: '',
  trainingMethod: [],
  detail: '',
  acceptedTerm: false,
  agreedToMarketingConsent: false,
}

interface Props {
  id?: string
  onSuccess?: () => void
}

function Form({ id, onSuccess }: Props): React.ReactElement {
  const {
    t,
    i18n: { language },
  } = useTranslation('common')

  const toast = useToast()

  const { data: utm } = useUTMContext()

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
  })

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { isSubmitting, errors },
    control,
    watch,
  } = methods

  const onSubmit = async (data: FormValue) => {
    if (isSubmitting) return

    try {
      await requests.post(
        `${env.APP_URL}/api/get-quotation`,
        { ...data, utm, language },
        {},
      )
      resetForm()

      logEvent({
        ga: {
          category: 'Submit Form',
          action: 'Success',
        },
        fb: {
          event: 'Lead',
        },
      })

      toast({
        ...DEFAULT_TOAST_OPTIONS,
        description: t('getQuotation.successToast'),
      })

      onSuccess && onSuccess()
    } catch (error: any) {
      toast(getErrorToastObject(error))
    }
  }

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      toast({
        ...DEFAULT_TOAST_OPTIONS,
        status: 'error',
        description: t('errors.incorrectData'),
      })
    }
  }, [errors])

  const isCheckedServices = watch([
    'services.implementation',
    'services.advisory',
    'services.training',
    'services.legal',
    'services.compliance',
  ]).some((value) => value)

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          spacing={{ base: 7, sm: 12, md: 16 }}
          sx={{ alignItems: 'stretch' }}
        >
          <Grid
            id={id}
            sx={{
              gridTemplateColumns: {
                base: '1fr',
                md: 'repeat(2, 1fr)',
              },
              gridGap: {
                base: 6,
                md: '5 8',
              },
              color: 'gray.900',
            }}
          >
            <FormControl isRequired isInvalid={Boolean(errors.firstName)}>
              <FormLabel>{t('getQuotation.form.firstName.label')}</FormLabel>
              <Input
                placeholder={t('getQuotation.form.firstName.placeholder')}
                {...register('firstName')}
              />
              {errors.firstName && (
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={Boolean(errors.lastName)}>
              <FormLabel>{t('getQuotation.form.lastName.label')}</FormLabel>
              <Input
                placeholder={t('getQuotation.form.lastName.placeholder')}
                {...register('lastName')}
              />
              {errors.lastName && (
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={Boolean(errors.email)}>
              <FormLabel>{t('getQuotation.form.email.label')}</FormLabel>
              <Input
                placeholder={t('getQuotation.form.email.placeholder')}
                type="email"
                {...register('email')}
              />
              {errors.email && (
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={Boolean(errors.tel)}>
              <FormLabel>{t('getQuotation.form.tel.label')}</FormLabel>
              <Input
                placeholder={t('getQuotation.form.tel.placeholder')}
                type="tel"
                {...register('tel')}
              />
              {errors.tel && (
                <FormErrorMessage>{errors.tel?.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={Boolean(errors.companyName)}>
              <FormLabel>{t('getQuotation.form.company.label')}</FormLabel>
              <Input
                placeholder={t('getQuotation.form.company.placeholder')}
                {...register('companyName')}
              />
              {errors.companyName && (
                <FormErrorMessage>
                  {errors?.companyName?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errors.jobTitle)}>
              <FormLabel>{t('getQuotation.form.jobTitle.label')}</FormLabel>
              <Input
                placeholder={t('getQuotation.form.jobTitle.placeholder')}
                {...register('jobTitle')}
              />
              {errors.jobTitle && (
                <FormErrorMessage>{errors?.jobTitle?.message}</FormErrorMessage>
              )}
            </FormControl>
            <Controller
              name="companySize"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl
                    isRequired
                    isInvalid={Boolean(errors.companySize)}
                  >
                    <FormLabel>
                      {t('getQuotation.form.companySize.label')}
                    </FormLabel>

                    <Select
                      icon={<Icon as={FiChevronDown} />}
                      value={value}
                      onChange={onChange}
                      sx={{
                        color: value ? 'inherit' : 'gray.400',
                      }}
                    >
                      <option value="" disabled>
                        {t('getQuotation.form.companySize.placeholder')}
                      </option>
                      <option value="small">
                        {t('getQuotation.form.companySize.options.0')}
                      </option>
                      <option value="medium">
                        {t('getQuotation.form.companySize.options.1')}
                      </option>
                      <option value="large">
                        {t('getQuotation.form.companySize.options.2')}
                      </option>
                    </Select>

                    {errors.companySize && (
                      <FormErrorMessage>
                        {errors?.companySize?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )
              }}
            />
            <FormControl
              isRequired
              isInvalid={Boolean(errors.services)}
              sx={{ gridColumn: { md: '1 / 3' } }}
            >
              <FormLabel>{t('getQuotation.form.services.label')}</FormLabel>
              <VStack
                spacing="4"
                sx={{ alignItems: 'flex-start', fontWeight: 'medium' }}
              >
                <Controller
                  name="services.implementation"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Checkbox
                        isRequired={!isCheckedServices}
                        onChange={onChange}
                        isChecked={value}
                      >
                        {t('getQuotation.form.services.options.0')}
                      </Checkbox>
                    )
                  }}
                />
                <Controller
                  name="services.advisory"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Checkbox
                        isRequired={!isCheckedServices}
                        onChange={onChange}
                        isChecked={value}
                      >
                        {t('getQuotation.form.services.options.1')}
                      </Checkbox>
                    )
                  }}
                />
                <Controller
                  name="services.training"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Checkbox
                        isRequired={!isCheckedServices}
                        onChange={onChange}
                        isChecked={value}
                      >
                        {t('getQuotation.form.services.options.2')}
                      </Checkbox>
                    )
                  }}
                />
                <Controller
                  name="services.legal"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Checkbox
                        isRequired={!isCheckedServices}
                        onChange={onChange}
                        isChecked={value}
                      >
                        {t('getQuotation.form.services.options.3')}
                      </Checkbox>
                    )
                  }}
                />
                <Controller
                  name="services.compliance"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Checkbox
                        isRequired={!isCheckedServices}
                        onChange={onChange}
                        isChecked={value}
                      >
                        {t('getQuotation.form.services.options.4')}
                      </Checkbox>
                    )
                  }}
                />
              </VStack>

              {errors.services && (
                <FormErrorMessage>
                  {errors?.services?.implementation?.message ||
                    errors?.services?.advisory?.message ||
                    errors?.services?.training?.message ||
                    errors?.services?.legal?.message ||
                    errors?.services?.compliance?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <ImplementationAdditionalFields />
            <TrainingAdditionalFields />
            <FormControl isInvalid={Boolean(errors.budget)}>
              <FormLabel>{t('getQuotation.form.budget.label')}</FormLabel>
              <InputGroup>
                <Input
                  placeholder="0"
                  min="0"
                  step="any"
                  type="number"
                  sx={{ borderRight: 'none' }}
                  {...register('budget')}
                />
                <InputRightAddon
                  sx={{ color: 'gray.400', fontWeight: 'medium' }}
                >
                  {t('getQuotation.form.budget.unit')}
                </InputRightAddon>
              </InputGroup>
              {errors.budget && (
                <FormErrorMessage>{errors.budget?.message}</FormErrorMessage>
              )}
            </FormControl>
            <Controller
              name="startWithIn"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl
                    isRequired
                    isInvalid={Boolean(errors.startWithIn)}
                  >
                    <FormLabel>
                      {t('getQuotation.form.startingDate.label')}
                    </FormLabel>

                    <VStack spacing="4" sx={{ alignItems: 'stretch' }}>
                      <Select
                        icon={<Icon as={FiChevronDown} />}
                        value={value}
                        onChange={onChange}
                        sx={{
                          color: value ? 'inherit' : 'gray.400',
                        }}
                      >
                        <option value="" disabled>
                          {t('getQuotation.form.startingDate.placeholder')}
                        </option>
                        <option value="ภายใน 1 เดือน">
                          {t('getQuotation.form.startingDate.options.0')}
                        </option>
                        <option value="ภายใน 3 เดือน">
                          {t('getQuotation.form.startingDate.options.1')}
                        </option>
                        <option value="ภายใน 6 เดือน">
                          {t('getQuotation.form.startingDate.options.2')}
                        </option>
                        <option value="อื่นๆ">
                          {t('getQuotation.form.startingDate.options.3')}
                        </option>
                      </Select>
                      <Collapse in={value === 'อื่นๆ'} animateOpacity>
                        <Input
                          placeholder={t(
                            'getQuotation.form.startingDate.conditionalInput.placeholder',
                          )}
                          isRequired={value === 'อื่นๆ'}
                          {...register('startWithInInput')}
                        />
                      </Collapse>
                    </VStack>

                    {errors.startWithIn && (
                      <FormErrorMessage>
                        {errors.startWithIn?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                )
              }}
            />
            <FormControl
              isInvalid={Boolean(errors.detail)}
              sx={{ gridColumn: { md: '1 / 3' } }}
            >
              <FormLabel>{t('getQuotation.form.details.label')}</FormLabel>
              <Textarea
                placeholder={t('getQuotation.form.details.placeholder')}
                sx={{ height: 28 }}
                {...register('detail')}
              />
              {errors.detail && (
                <FormErrorMessage>{errors.detail?.message}</FormErrorMessage>
              )}
            </FormControl>
            <VStack
              spacing="4"
              sx={{
                alignItems: 'flex-start',
                fontWeight: 'medium',
                gridColumn: { md: '1 / 3' },
              }}
            >
              <Controller
                name="acceptedTerm"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      isRequired
                      onChange={onChange}
                      isChecked={value}
                      sx={{ alignItems: 'flex-start' }}
                    >
                      <Trans t={t} i18nKey="getQuotation.form.agreedToTerms">
                        ฉันยอมรับ
                        <Link
                          isExternal
                          sx={{ textDecoration: 'underline !important' }}
                          href={`/${language}${TERMS_OF_USE.pathname}`}
                        >
                          เงื่อนไขในการใช้เว็บไซต์
                        </Link>
                        และ
                        <Link
                          isExternal
                          sx={{ textDecoration: 'underline !important' }}
                          href={`/${language}${PRIVACY_POLICY.pathname}`}
                        >
                          นโยบายความเป็นส่วนตัว
                        </Link>
                      </Trans>
                    </Checkbox>
                  )
                }}
              />
              <Controller
                name="agreedToMarketingConsent"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      onChange={onChange}
                      isChecked={value}
                      sx={{ alignItems: 'flex-start' }}
                    >
                      <Trans t={t} i18nKey="getQuotation.form.marketingConsent">
                        ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ&nbsp;
                        PDPA Core &nbsp;รวมถึงบริการอื่น ๆ ที่เกี่ยวข้องกับ PDPA{' '}
                        <br />
                        (คุณสามารถยกเลิกได้ทุกเมื่อ)
                      </Trans>
                    </Checkbox>
                  )
                }}
              />
            </VStack>
          </Grid>
          <Button
            isLoading={isSubmitting}
            type="submit"
            size="xl"
            onClick={() => {
              logEvent({
                ga: {
                  category: 'CTA',
                  action: 'Click Get Quote',
                  label: 'Contact Us',
                },
              })
            }}
            sx={{
              alignSelf: 'center',
              w: 'fit-content',
              mx: 'auto',
            }}
          >
            {t('getQuotation.form.submitButton')}
          </Button>
        </VStack>
      </form>
    </FormProvider>
  )
}

export default Form
