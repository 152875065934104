import { Text, VStack, Box } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import FormComponent from '@/features/landing/components/Form'

interface Props {
  onSuccess?: () => void
  isShowBg?: boolean
}

function Form({ onSuccess, isShowBg = true }: Props): React.ReactElement {
  const { t } = useTranslation('common')

  return (
    <Box
      as="section"
      id="get-quotation"
      sx={{
        w: 'full',
        backgroundImage: isShowBg
          ? {
              base: 'none',
              md: 'url("/images/landing/justice-scale.svg")',
            }
          : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '150% 120%',
        backgroundSize: '870px 870px',
      }}
    >
      <VStack
        spacing={{
          base: 4,
          md: 8,
        }}
        layerStyle="container"
        sx={{
          alignItems: 'stretch',
        }}
      >
        <VStack
          spacing="1"
          layerStyle="landingSectionHeading"
          sx={{
            alignItems: 'center',
          }}
        >
          <Text as="h1" textStyle="landingSectionTitle">
            {t('getQuotation.title')}
          </Text>
          <Text as="h2" textStyle="landingSectionSubtitle">
            {t('getQuotation.subtitle')}
          </Text>
        </VStack>
        <FormComponent onSuccess={onSuccess} />
      </VStack>
    </Box>
  )
}

export default Form
