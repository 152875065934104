import {
  Collapse,
  FormControl,
  FormLabel,
  Stack,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Icon,
  SimpleGrid,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useFormContext, Controller, useWatch } from 'react-hook-form'
import { FiChevronDown } from 'react-icons/fi'

const RADIO_STYLE = {
  fontWeight: 'light',
}

export function ImplementationAdditionalFields(): React.ReactElement {
  const { t } = useTranslation('common')

  const { control } = useFormContext()

  const implitationService = useWatch({
    control,
    name: 'services.implementation',
    disabled: false,
  })
  const complianceService = useWatch({
    control,
    name: 'services.compliance',
    disabled: false,
  })

  return (
    <Box sx={{ gridColumn: { md: '1 / 3' } }}>
      <Collapse in={Boolean(implitationService || complianceService)}>
        <SimpleGrid
          sx={{
            gridTemplateColumns: {
              base: '1fr',
              md: 'repeat(2, 1fr)',
            },
            gridGap: {
              base: 6,
              md: '5 8',
            },
            color: 'gray.900',
          }}
        >
          <Controller
            name="personalInfoUsageAmount"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl>
                  <FormLabel>
                    {t('getQuotation.form.personalInfoUsageAmount.label')}
                  </FormLabel>

                  <RadioGroup
                    icon={<Icon as={FiChevronDown} />}
                    defaultValue=""
                    value={value}
                    onChange={onChange}
                  >
                    <Stack sx={RADIO_STYLE}>
                      <Radio value="ใช้ข้อมูลส่วนบุคคลจำนวนมาก (จำนวนเจ้าของข้อมูลเกิน 50,000 คน)">
                        {t(
                          'getQuotation.form.personalInfoUsageAmount.options.0',
                        )}
                      </Radio>
                      <Radio value="ใช้ข้อมูลส่วนบุคคลจำนวนปานกลาง (จำนวนเจ้าของข้อมูลเกิน 20,000 คน)">
                        {t(
                          'getQuotation.form.personalInfoUsageAmount.options.1',
                        )}
                      </Radio>
                      <Radio value="ใช้ข้อมูลส่วนบุคคลจำนวนน้อย (จำนวนเจ้าของข้อมูลเกิน 1,000 คน)">
                        {t(
                          'getQuotation.form.personalInfoUsageAmount.options.2',
                        )}
                      </Radio>
                      <Radio value="ใช้ข้อมูลส่วนบุคคลจำนวนน้อยมาก (จำนวนเจ้าของข้อมูลต่ำกว่า 500 คน)">
                        {t(
                          'getQuotation.form.personalInfoUsageAmount.options.3',
                        )}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )
            }}
          />
          <Controller
            name="hasImplementedPDPA"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl>
                  <FormLabel>
                    {t('getQuotation.form.hasImplementedPDPA.label')}
                  </FormLabel>

                  <RadioGroup
                    icon={<Icon as={FiChevronDown} />}
                    defaultValue=""
                    value={value}
                    onChange={onChange}
                  >
                    <Stack sx={RADIO_STYLE}>
                      <Radio value="ทำไปแล้วประมาณ 100%">
                        {t('getQuotation.form.hasImplementedPDPA.options.0')}
                      </Radio>
                      <Radio value="ทำไปแล้วประมาณ 75%">
                        {t('getQuotation.form.hasImplementedPDPA.options.1')}
                      </Radio>
                      <Radio value="ทำไปแล้วประมาณ 50%">
                        {t('getQuotation.form.hasImplementedPDPA.options.2')}
                      </Radio>
                      <Radio value="ยังไม่ได้ทำหรือต่ำกว่า 50%">
                        {t('getQuotation.form.hasImplementedPDPA.options.3')}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )
            }}
          />
          <Controller
            name="hasItSecurityTeam"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl>
                  <FormLabel>
                    {t('getQuotation.form.hasItSecurityTeam.label')}
                  </FormLabel>

                  <RadioGroup
                    icon={<Icon as={FiChevronDown} />}
                    defaultValue=""
                    value={value}
                    onChange={onChange}
                  >
                    <Stack sx={RADIO_STYLE}>
                      <Radio value="มี">
                        {t('getQuotation.form.hasItSecurityTeam.options.0')}
                      </Radio>
                      <Radio value="ไม่มี">
                        {t('getQuotation.form.hasItSecurityTeam.options.1')}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )
            }}
          />
          <Controller
            name="transferOutCountry"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl>
                  <FormLabel>
                    {t('getQuotation.form.transferOutCountry.label')}
                  </FormLabel>

                  <RadioGroup
                    icon={<Icon as={FiChevronDown} />}
                    defaultValue=""
                    value={value}
                    onChange={onChange}
                  >
                    <Stack sx={RADIO_STYLE}>
                      <Radio value="ใช่ บริษัทมีการส่งข้อมูลออกไปยังต่างประเทศ">
                        {t('getQuotation.form.transferOutCountry.options.0')}
                      </Radio>
                      <Radio value="ไม่ใช่ บริษัทไม่มีการส่งข้อมูลออกไปยังต่างประเทศ">
                        {t('getQuotation.form.transferOutCountry.options.1')}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )
            }}
          />
        </SimpleGrid>
      </Collapse>
    </Box>
  )
}

export function TrainingAdditionalFields(): React.ReactElement {
  const { t } = useTranslation('common')

  const { control } = useFormContext()

  const trainingService = useWatch({
    control,
    name: 'services.training',
  })

  return (
    <Box sx={{ gridColumn: { md: '1 / 3' } }}>
      <Collapse in={trainingService}>
        <Controller
          name="trainingMethod"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl>
                <FormLabel>
                  {t('getQuotation.form.trainingMethod.label')}
                </FormLabel>

                <CheckboxGroup
                  defaultValue={[]}
                  value={value}
                  onChange={onChange}
                >
                  <Stack sx={RADIO_STYLE}>
                    <Checkbox value="จัดอบรมที่บริษัท (on-site)">
                      {t('getQuotation.form.trainingMethod.options.0')}
                    </Checkbox>
                    <Checkbox value="จัดอบรมแบบออนไลน์ผ่าน video conference (live-training)">
                      {t('getQuotation.form.trainingMethod.options.1')}
                    </Checkbox>
                    <Checkbox value="ให้พนักงานเรียนด้วยตัวเอง (e-learning)">
                      {t('getQuotation.form.trainingMethod.options.2')}
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </FormControl>
            )
          }}
        />
      </Collapse>
    </Box>
  )
}
