import * as z from 'zod'

import { LOCALES } from '@/lib/localization/constants'
import { UTMDataSchema } from '@/lib/gtm'

export const schema = z.object({
  firstName: z.string().nonempty({ message: 'First name is required' }),
  lastName: z.string().nonempty({ message: 'Last name is required' }),
  email: z
    .string()
    .nonempty({ message: 'Email is required' })
    .email({ message: 'Must be valid email' }),
  tel: z
    .string().nonempty({ message: 'Phone number is required' })
    .regex(/^\d{10}$/, { message: 'Phone number must be a 10-digit number' }),
  jobTitle: z.string(),
  companyName: z.string().nonempty({ message: 'Company name is required' }),
  companySize: z.string().nonempty({ message: 'Company size is required' }),
  services: z.object({
    implementation: z.boolean(),
    advisory: z.boolean(),
    training: z.boolean(),
    legal: z.boolean(),
    compliance: z.boolean(),
  }),
  budget: z.string(),
  startWithIn: z
    .string()
    .nonempty({ message: 'Please select when you would like to start' }),
  startWithInInput: z.string(),
  personalInfoUsageAmount: z.string(),
  hasImplementedPDPA: z.string(),
  hasItSecurityTeam: z.string(),
  transferOutCountry: z.string(),
  trainingMethod: z.string().array(),
  detail: z.string(),
  acceptedTerm: z.boolean(),
  agreedToMarketingConsent: z.boolean(),
})

export type FormValue = z.infer<typeof schema>

export const payloadSchema = schema.extend({
  language: z.enum(LOCALES),
  utm: UTMDataSchema,
})

export type PayloadValue = z.infer<typeof payloadSchema>
